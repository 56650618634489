import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { Schema, SchemaFlatNode } from '../../model/schema';

import { SQLQueryService } from '../sql-query.service';
import { DeploymentObject } from 'projects/api/src'; 

import { TopbarControlService } from '@intersystems/header';
import { SQLResponse } from 'src/app/deployments/icca-common/model/sql-response'; 
import { NotificationService } from '@intersystems/notification';
import { DeploymentsService } from 'src/app/deployments/deployments.service';
import { IRISAuthService } from 'src/app/deployments/icca-common/components/iris-login/iris-auth.service'; 
import { DeploymentInfo } from '../../../icca-common/model/deployment-info';
import { Subject, Subscription } from 'rxjs';
import { IccaCommonService } from 'src/app/deployments/icca-common/services/icca-common.service';


@Component({
  selector: 'app-schema-browser',
  templateUrl: './schema-browser.component.html',
  styleUrls: ['./schema-browser.component.scss']
})
export class SchemaBrowserComponent implements OnInit, OnDestroy {

  
  private _transformer = (node: Schema, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.value,
      level: level,
      id: node.id,
    };
  }
  private sub = new Subscription();

  loadInProgress:boolean = false;

  dragging:boolean;
  deployment: DeploymentObject | undefined;
  deploymentInfo: DeploymentInfo | undefined;

  treeControl = new FlatTreeControl<SchemaFlatNode>(
    node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
    this._transformer, node => node.level, node => node.expandable, node => node.children);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  
  schemaData: Schema[] = [];

  infoObject= {
    infoTitle:'Schema Tree',
    htmlText: "Shows all schemas and tables in the namespace you are connected to, including both \
    those created by importing DDL and CSV files."
  };

  

  constructor(
    private sqlQueryService: SQLQueryService,
    private location: Location,
    private topbarControlService: TopbarControlService,
    private router: Router,
    private route: ActivatedRoute,  
    private notificationSvc: NotificationService,
    private deploymentsService: DeploymentsService,
    private iccaCommonService: IccaCommonService,
    private irisAuthService: IRISAuthService,
  ) { 

    this.dataSource.data = this.schemaData;

   


  }

  ngOnInit(): void {

    const deploymentId = this.route.snapshot.paramMap.get('deploymentId');
    const deployment:DeploymentObject = this.deploymentsService.findDeployment(this.deploymentsService.deployments,deploymentId);
    const connectionInfo = this.irisAuthService.getIRISConnectionInfo(deploymentId, deployment.deploymenttype);
      if ((connectionInfo.username=='')||(connectionInfo.password=='')) {
        this.router.navigate(['/deployments', deploymentId, 'irislogin']);
        return;
      }
      if (!deployment) return;

      //deploymentInfo: DeploymentInfo | undefined;
      this.sub.add(this.iccaCommonService.loadDeploymentInfo$(deployment.deploymenttype,deploymentId,deployment.region).subscribe(deploymentInfo => {
        this.deploymentInfo = deploymentInfo;
        if (this.deploymentInfo.info.deploymentType=='iml') {
          this.infoObject.htmlText="Shows all schemas and tables in the namespace you are connected to, including both \
          those created by importing DDL and CSV files and the <a href='https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=GIML_Maintain#GIML_Using_Maintain_View' \
          target='_blank'>INFORMATION_SCHEMA class</a>, which you can query for information about \
          models, trained models, training runs, validation runs, and validation metrics.";
        }
        this.getSchemas();
      }));
  }


  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.sub.unsubscribe();
 }
 
  hasChild = (_: number, node: SchemaFlatNode) => node.expandable;

 
  getSchemas(): void {
    if (this.loadInProgress) return
    
    this.loadInProgress = true;
    const deploymentId = this.route.snapshot.paramMap.get('deploymentId');
    const deployment:DeploymentObject = this.deploymentsService.findDeployment(this.deploymentsService.deployments,deploymentId);
   
   //this query orders by schema and table and column so we only need to take 1 pass through the result set.
    var schemaQuery="SELECT SCHEMA_NAME As Name, 'schema' As type, 'root' As TABLE_SCHEMA, 'root' As TABLE_NAME, SCHEMA_NAME as order  ";
    schemaQuery=schemaQuery + " FROM INFORMATION_SCHEMA.SCHEMATA WHERE NOT SCHEMA_NAME %STARTSWITH '%' AND NOT SCHEMA_NAME %STARTSWITH 'ENS' ";
    if (this.deploymentInfo.info.deploymentType!='iml') {
      schemaQuery=schemaQuery + "AND SCHEMA_NAME <> 'INFORMATION_SCHEMA'  ";
    }
    schemaQuery=schemaQuery + " UNION ALL  ";
    schemaQuery=schemaQuery + " SELECT TABLE_NAME As Name, 'table' As type, TABLE_SCHEMA, 'root' As TABLE_NAME,   ";
    schemaQuery=schemaQuery + " {fn CONCAT( {fn CONCAT(TABLE_SCHEMA, '*')}, TABLE_NAME)}  as order   ";
    schemaQuery=schemaQuery + " FROM INFORMATION_SCHEMA.TABLES WHERE NOT TABLE_SCHEMA %STARTSWITH '%' AND NOT TABLE_SCHEMA %STARTSWITH 'ENS' AND ( (TABLE_SCHEMA <> 'INFORMATION_SCHEMA') ";
    if (this.deploymentInfo.info.deploymentType=='iml') {
      schemaQuery=schemaQuery + "OR ((TABLE_SCHEMA = 'INFORMATION_SCHEMA') AND (TABLE_NAME %STARTSWITH 'ML_'))";
    }
    schemaQuery=schemaQuery + ") ";
    schemaQuery=schemaQuery + " UNION ALL  ";
    schemaQuery=schemaQuery + " SELECT COLUMN_NAME As Name, 'field' As type, TABLE_SCHEMA, TABLE_NAME,  ";
    schemaQuery=schemaQuery + " {fn CONCAT( {fn CONCAT( {fn CONCAT( {fn CONCAT(TABLE_SCHEMA, '*')}, TABLE_NAME)}, '*')},COLUMN_NAME )}  as order    ";
    schemaQuery=schemaQuery + " FROM INFORMATION_SCHEMA.COLUMNS WHERE NOT TABLE_SCHEMA %STARTSWITH '%' AND NOT TABLE_SCHEMA %STARTSWITH 'ENS' AND ( (TABLE_SCHEMA <> 'INFORMATION_SCHEMA') ";
    if (this.deploymentInfo.info.deploymentType=='iml') {
      schemaQuery=schemaQuery + "OR ((TABLE_SCHEMA = 'INFORMATION_SCHEMA') AND (TABLE_NAME %STARTSWITH 'ML_'))";
    }
    schemaQuery=schemaQuery + ") ";
    schemaQuery=schemaQuery + " UNION ALL  ";
    schemaQuery=schemaQuery + " SELECT name As Name, 'collection' As type, 'collections' As TABLE_SCHEMA, name As TABLE_NAME,  ";
    schemaQuery=schemaQuery + " {fn CONCAT( {fn CONCAT( {fn CONCAT( {fn CONCAT('collections', '*')}, name)}, '*')},name )}  as order    ";
    schemaQuery=schemaQuery + " FROM JSON_TABLE(COLLECTION syscollections) WHERE name NOT LIKE 'sys%'";
    
    schemaQuery=schemaQuery + " ORDER BY order,type,name ";

    this.schemaData=[];

    this.sub.add(this.sqlQueryService.executeSQLStatement(deployment,schemaQuery)
    .subscribe(sqlResponse => {
      var idValue:string='';
      var loop:{i:number} = {i:0};
      var collectionsSchemaAdded:boolean=false;

      for (loop.i = 0; loop.i < sqlResponse["resultSet"].data.length ; loop.i++) {
        if (sqlResponse["resultSet"].data[loop.i][1]=='schema') {
          idValue=sqlResponse["resultSet"].data[loop.i][0]; //id for schema is the schema name
          this.schemaData.push({
            id: idValue,
            value:sqlResponse["resultSet"].data[loop.i][0],
            type: 'schema',
            children: this.getTablesforSchema(sqlResponse,loop,sqlResponse["resultSet"].data[loop.i][0])
          })
        
        } else if (!collectionsSchemaAdded && sqlResponse["resultSet"].data[loop.i][1]=='collection') {
          //add in a Doc Collections schema and list of collections
          collectionsSchemaAdded=true;
          idValue='Collections'; 
          this.schemaData.push({
            id: idValue,
            value:'Document collections',
            type: 'schema',
            children: this.getCollections(sqlResponse,loop)
          })
        }
        
      } 
      this.dataSource.data=this.schemaData;
      
      this.loadInProgress = false;
    
    }));

    
    return
    
  }
  getTablesforSchema(sqlResponse:SQLResponse[],loop:{i:number},schema:string,):Schema[] {
    var tableSchema:Schema[] = [];
    var idValue:string='';
    loop.i++;
     //result set is ordered by schema and table, exit if schema or table changes
     for (loop.i = loop.i; ((loop.i < sqlResponse["resultSet"].data.length)&&(sqlResponse["resultSet"].data[loop.i][2]==schema)&&(sqlResponse["resultSet"].data[loop.i][1]=='table') ) ; loop.i++) {
      idValue=`${schema}.${sqlResponse["resultSet"].data[loop.i][0]}`; //id for table is schema.table
      tableSchema.push(
        {
        id: idValue,
        value: sqlResponse["resultSet"].data[loop.i][0],
        type: 'table',
        children: this.getColumnsforSchemaTable(sqlResponse,loop,sqlResponse["resultSet"].data[loop.i][2],sqlResponse["resultSet"].data[loop.i][0])
        }
      );
      
    }
    //decrement it back 1 so we don't skip over the schema
    loop.i--;
    return tableSchema
  }

  getColumnsforSchemaTable(sqlResponse:SQLResponse[], loop:{i:number}, schema:string, table:string):Schema[] {
    loop.i++;
    var tableSchema:Schema[] = [];
    var idValue:string='';
    //result set is ordered by schema, table, col so if we change schemas, return
    for (loop.i = loop.i; ((loop.i < sqlResponse["resultSet"].data.length)&&(sqlResponse["resultSet"].data[loop.i][2]==schema)&&(sqlResponse["resultSet"].data[loop.i][3]==table)&&(sqlResponse["resultSet"].data[loop.i][1]=='field')); loop.i++) {
      idValue=`${schema}.${table}.${sqlResponse["resultSet"].data[loop.i][0]}`; //id for column is schema.table.column
      tableSchema.push(
        {
          id: idValue,
          type: 'column',
          value: sqlResponse["resultSet"].data[loop.i][0],
        }
      );
    }
    //decrement it back 1 so we don't skip over the table
    loop.i--;
    return tableSchema
  }

  
  getCollections(sqlResponse:SQLResponse[],loop:{i:number}):Schema[] {
    var tableSchema:Schema[] = [];
    var idValue:string='';
    //loop.i++;
     //result set is ordered by schema and table, exit if schema or table changes
     for (loop.i = loop.i; ((loop.i < sqlResponse["resultSet"].data.length)&&(sqlResponse["resultSet"].data[loop.i][1]=='collection')) ; loop.i++) {
      idValue=`collection.${sqlResponse["resultSet"].data[loop.i][0]}`; //id for table is schema.table
      tableSchema.push(
        {
        id: idValue,
        value: sqlResponse["resultSet"].data[loop.i][0],
        type: 'table',
        //children: this.getColumnsforSchemaTable(sqlResponse,loop,sqlResponse["resultSet"].data[loop.i][2],sqlResponse["resultSet"].data[loop.i][0])
        }
      );
      
    }
    //decrement it back 1 so we don't skip over the schema
    loop.i--;
    return tableSchema
  }


  dragStart() {
    this.dragging = true;
  }
  dragEnd() {
    this.dragging = false;
  }

  nodeClick(node:SchemaFlatNode) {
    if (node.expandable) {
      this.treeControl.toggle(node);
    }
  }

}
