import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TableConfig } from '@intersystems/table';
import { TableEnhancedIdService } from 'src/app/core/table-enhanced-id.service';
import { SharedUtils } from '../../../../shared/shared-utils';

@Component({
  selector: 'app-tenants-form',
  templateUrl: './tenants-table.component.html',
  styleUrls: ['./tenants-table.component.scss'],
})
export class TenantsTableComponent {
  @Input() tenants!: any;

  @Output() manageAccess = new EventEmitter<any>();
  @Output() refresh = new EventEmitter<void>();
  @Output() acceptInvitation = new EventEmitter<any>();
  @Output() rejectInvitation = new EventEmitter<any>();
  @Output() leaveTenant = new EventEmitter<any>();

  loading: false;

  tenantsTableConfig: TableConfig = {
    key: 'tenants-table',
    header: { title: 'Current Tenants' },
    cssNoDataMessageClass: 'no-data-message',
    noDataMessage: 'No tenants available',
    cssTRClassFromRow: TableEnhancedIdService.setTableRowIdColumn('name'),
    columns: [
      SharedUtils.getTableColumn('name', 'Name'),
      SharedUtils.getTableColumn('role', 'Role'),
      SharedUtils.getTableColumn('default', 'Is Default'),
      SharedUtils.getTableColumn('status'),
      {
        key: 'action',
        id: 'action',
        title: 'Actions',
        cellDisplay: {
          preset: 'actionsIcons',
          actionsIcons: {
            iconsOrder: ['edit', 'done', 'leave', 'reject'],
            edit: {
              id: 'edit',
              tooltip: { text: 'Manage Access' },
              callback: (_event, row) => this.manageAccess.emit(row),
              hidden: row => row.status !== 'Active',
            },
            leave: {
              id: 'leave',
              tooltip: { text: 'Leave Tenant' },
              customSvgIcon: 'leave',
              callback: (_event, row) => this.leaveTenant.emit(row),
              hidden: row => row.status !== 'Active' || row.role === 'admin',
            },
            reject: {
              id: 'reject',
              tooltip: { text: 'Reject Invitation' },
              callback: (_event, row) => this.rejectInvitation.emit(row),
              hidden: row => row.status !== 'Pending',
              customSvgIcon: 'cancel',
            },
            done: {
              id: 'accept',
              tooltip: { text: 'Accept Invitation' },
              callback: (_event, row) => this.acceptInvitation.emit(row),
              hidden: row => row.status !== 'Pending',
              customSvgIcon: 'done',
            },
          },
        },
      },
    ],
  };
}
