import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaginatorConfig, TableConfig, TableService } from '@intersystems/table';
import { User } from 'src/app/core/interfaces/user';
import { TableEnhancedIdService } from 'src/app/core/table-enhanced-id.service';
import { SharedUtils } from 'src/app/shared/shared-utils';
import { Sort } from '@angular/material/sort';
import { SharedService } from '../../../shared/services/shared.service';
import { PerformanceProfile } from 'admin-api';

export interface EditExpiresEvent {
  username: string;
  expires_at: string | boolean;
}

@Component({
  selector: 'app-performanceprofiles-table',
  templateUrl: './performanceprofiles-table.component.html',
  styleUrls: ['./performanceprofiles-table.component.scss'],
})
export class PerformanceProfilesTableComponent {
  @Input() performanceProfiles!: PerformanceProfile[];
  @Output() editExpires = new EventEmitter<EditExpiresEvent>();

  constructor(public tableService: TableService, public sharedService: SharedService) {}

  tableConfig: TableConfig = {
    key: 'performanceprofiles-table',
    header: {
      title: 'Performance Profiles',
    },
    noDataMessage: 'No profiles',
    useSearch: true,
    searchConfig: {
      placeholderLabel: 'Search',
    },
    cssTRClassFromRow: TableEnhancedIdService.setTableRowIdColumn('username'),
    sort: {
      sortFunction: (event: Sort, data: any) => {
        return data.sort((a: any, b: any) => {
          const isAsc = event.direction === 'asc';
          switch (event.active) {
            case 'perfprofileid':
              return this.tableService.compareAlphaNumeric(a.perfprofileid, b.perfprofileid, isAsc);
            case 'created_at':
              return this.sharedService.compareDates(a.created_at, b.created_at, isAsc);
            default:
              return 0;
          }
        });
      },
    },
    columns: [
      {
        id: 'perfprofileid',
        key: 'perfprofileid',
        title: 'Profile ID',
        sortable: true,
        cellDisplay: {
          model: 'perfprofileid',
        },
      },
      SharedUtils.getTableColumn('size'),
      SharedUtils.getTableColumn('cloud'),
      SharedUtils.getTableColumn('offer'),
      SharedUtils.getTableColumn('instance_type', 'Cloud API'),
      SharedUtils.getTableColumn('gmheap'),
      SharedUtils.getTableColumn('locksiz'),
      SharedUtils.getTableColumn('globals8kb'),
      SharedUtils.getTableColumn('routines'),
      SharedUtils.getTableColumn('hugepages'),
    ],
  };

  paginatorConfig: PaginatorConfig = {
    pageSize: 20,
    pageSizeOptions: [10, 20, 50],
  };

  onExpiresEdit(row: User) {
    this.editExpires.emit({ username: row.username, expires_at: row.expires_at });
  }
}
