import { Component, OnInit } from '@angular/core';
import { DeploymentsService } from '../deployments/deployments.service';
import { AuthService } from '../core/auth.service';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SharedService } from '../shared/services/shared.service';
import { RequestComponent } from './dialogs/request.component';
import { Subscription } from '../core/interfaces/subscription';
import { SUBSCRIPTIONS_DATA } from '../shared/subscriptions-data';

@Component({
  selector: 'app-cloud-services',
  templateUrl: './cloud-services-page.component.html',
  styleUrls: ['./cloud-services-page.component.scss'],
})
export class CloudServicesPageComponent implements OnInit {
  public user: any;

  private subscriptions = SUBSCRIPTIONS_DATA;
  public activeSubscriptions: Subscription[] = [];
  public inactiveSubscriptions: Subscription[] = [];

  constructor(
    private deploymentsService: DeploymentsService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private sharedService: SharedService,
  ) {}

  ngOnInit(): void {
    this.user = this.authService.user;
    this.subscriptions.forEach(subscription => {
      this.isActiveSubscription(subscription.productCode)
        ? this.activeSubscriptions.push({ ...this.user.subscriptions[subscription.productCode], ...subscription })
        : this.inactiveSubscriptions.push(subscription);
    });
  }

  isActiveSubscription(productCode) {
    return this.user.subscriptions[productCode] && this.user.subscriptions[productCode].status != 'unsubscribe-success';
  }

  onRequestAccess(data: { name: string; request: string }): void {
    const dialogRef = this.dialog.open(RequestComponent, {
      data,
    });
    dialogRef
      .afterClosed()
      .pipe(
        filter(result => result),
        switchMap(feedback => this.authService.submitFeedback(data.name, feedback)),
        tap(() => this.sharedService.showSuccess('Thank you for your request, our team will contact you.')),
        catchError(() =>
          of(
            this.sharedService.showAlert(
              'Error submitting request. Please send an email to portal-dev@isccloud.io detailing your case.',
            ),
          ),
        ),
      )
      .subscribe();
  }

  onTrial(data: Subscription): void {
    this.router.navigate(['/cloudservices/' + data.productCode], { queryParams: { trial: data.type } });
  }

  onSubscribe(data: Subscription): void {
    this.router.navigate(['/cloudservices/' + data.productCode]);
  }

  onCreateDeployment(type: string) {
    this.router.navigate(['/deployments', 'create']);
  }
}
