<app-section [header]="subscription?.name">
  <div *ngIf="subscription">
    <ng-container *ngIf="!trialOfferType">
      <div>
        <h3>Pricing Information</h3>
        <p>
          {{ subscription.pricing?.description }}
        </p>
      </div>

      <table class="pricing-block">
        <tr>
          <th>{{ subscription.pricing?.measure }}</th>
          <th *ngIf="hasPricing('aws')">AWS</th>
          <th *ngIf="hasPricing('google')">Google</th>
          <th *ngIf="hasPricing('azure')">Azure</th>
        </tr>
        <tr *ngFor="let unit of subscription.pricing?.units">
          <td>{{ unit.name }}</td>
          <td *ngIf="hasPricing('aws')">{{ unit.aws }}</td>
          <td *ngIf="hasPricing('google')">{{ unit.google }}</td>
          <td *ngIf="hasPricing('azure')">{{ unit.azure }}</td>
        </tr>
      </table>
      <div>
        <h3>Subscribe</h3>
        <p>No matter where you subscribe to this service, you will have access to any supported cloud providers.</p>
        <a
          (click)="request.emit({ name: subscription.name, request: 'a subscription via InterSystems' })"
          target="_blank"
          mat-stroked-button
          color="primary"
          >Subscribe with <mat-icon class="cloud-provider-icon" svgIcon="InterSystemsIcon"></mat-icon>
        </a>

        <div *ngIf="subscription.pricing?.subscriptionUrls" class="subscriptionButtons">
          <p>You can also subscribe using your account at one of cloud providers</p>
          <a
            *ngIf="subscription.pricing.subscriptionUrls.aws"
            [href]="subscription.pricing.subscriptionUrls.aws"
            target="_blank"
            mat-stroked-button
            color="primary"
            >Subscribe with <mat-icon class="cloud-provider-icon" svgIcon="aws"></mat-icon>
          </a>
          <a
            *ngIf="subscription.pricing.subscriptionUrls.google"
            [href]="subscription.pricing.subscriptionUrls.google"
            target="_blank"
            mat-stroked-button
            color="primary"
            >Subscribe with <mat-icon class="cloud-provider-icon" svgIcon="google"></mat-icon>
          </a>
          <a
            *ngIf="subscription.pricing.subscriptionUrls.azure"
            [href]="subscription.pricing.subscriptionUrls.azure"
            target="_blank"
            mat-stroked-button
            color="primary"
            >Subscribe with <mat-icon class="cloud-provider-icon" svgIcon="microsoft"></mat-icon>
          </a>
        </div>
        <p class="terms">
          By subscribing to this offer, you agree to the following terms and conditions:<br />
          <span *ngFor="let terms of subscription.termsAndConditions">
            <a [href]="terms.url">{{ terms.name }}</a>
          </span>
        </p>
      </div>
    </ng-container>

    <ng-container *ngIf="trialOfferType">
      <fr-table
        Id="trial-offers-table"
        [config]="trialOffersTableConfig"
        [paginatorConfig]="trialOffersTablePaginatorConfig"
        [data]="tenants ?? []"
      >
        <div class="custom-table-header-space" table-header-space></div>

        <div class="table-sub-header" above-table-body-space></div>
      </fr-table>

      <div>
        <h3>Trial Terms</h3>
        <table class="pricing-block">
          <tr *ngFor="let term of terms">
            <td>{{ term.key }}</td>
            <td>{{ term.value }}</td>
          </tr>
        </table>
      </div>
    </ng-container>

    <div *ngIf="subscription.productOverview">
      <h3>Product Overview</h3>
      <p [innerHTML]="subscription.productOverview"></p>
    </div>
    <div class="youtube-video-container" *ngIf="subscription.productVideoId">
      <iframe
        width="560"
        height="315"
        [attr.src]="'https://www.youtube-nocookie.com/embed/' + subscription.productVideoId | safe: 'resourceUrl'"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</app-section>
