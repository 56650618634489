import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subscription } from '../../../core/interfaces/subscription';
import { environment } from 'src/environments/environment';
import { User } from 'api';

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss'],
})
export class SubscriptionsComponent {
  @Input() activeSubscriptions!: Subscription[];
  @Input() user!: User;
  @Input() inactiveSubscriptions!: Subscription[];

  @Output() request = new EventEmitter<{ name: string; request: string }>();
  @Output() subscribe = new EventEmitter<Subscription>();
  @Output() trial = new EventEmitter<Subscription>();
  @Output() createDeployment = new EventEmitter<string>();

  showOffer(offerType): boolean {
    //if offer is explicitly hidden, hide it.
    if (environment['HIDDEN_OFFERS']) {
      if (environment['HIDDEN_OFFERS'].includes(offerType)) {
        return false;
      }
    }
    //if VISIBLE_OFFERS array is defined, then only show what's in VISIBLE_OFFERS
    return environment['VISIBLE_OFFERS'] ? environment['VISIBLE_OFFERS'].includes(offerType) : true;
  }
  canStartTrial(): boolean {
    if (this.user.is_admin) return true;
    return !(environment?.STAGE == 'live' || environment?.STAGE == 'prod' || environment?.STAGE == 'test');
  }
}
