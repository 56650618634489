import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaginatorConfig, TableConfig, TableService } from '@intersystems/table';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { Deployment, Deploymenttype, MamStatusEnum, Status } from 'src/app/core/interfaces/deployment';
import { TableEnhancedIdService } from 'src/app/core/table-enhanced-id.service';
import { SharedUtils } from 'src/app/shared/shared-utils';
import { Sort } from '@angular/material/sort';
import { FDN } from '@intersystems/isc-form';
import { SharedService } from '../../../shared/services/shared.service';
import { DeploymentsService } from 'src/app/deployments/deployments.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-deployments-table',
  templateUrl: './deployments-table.component.html',
  styleUrls: ['./deployments-table.component.scss'],
})
export class DeploymentsTableComponent {
  _deployments$ = new BehaviorSubject<(Deployment & { isUserExpired: boolean })[]>(null);

  @Input() set deployments(val) {
    this._deployments$.next(val);
  }

  get deployments() {
    return this._deployments$.value;
  }

  filter$ = new BehaviorSubject<any>({
    filterGroup: {
      service: false,
      status: {
        name: 'COMPLETE',
        value: 'COMPLETE',
      },
      ownerName: undefined,
      tenantName: undefined,
      region: false,
    },
  });

  get filter() {
    return this.filter$.value;
  }

  set filter(val) {
    this.filter$.next(val);
  }

  filteredDeployments$ = combineLatest([this._deployments$, this.filter$]).pipe(
    map(([deployments, filter]) => {
      return deployments?.filter(item => {
        let res = true;
        if (filter.filterGroup?.service?.value && filter?.filterGroup?.service?.value !== item.deploymenttype)
          res = false;
        if (filter.filterGroup?.status?.value && filter?.filterGroup?.status?.value !== this.getStatus(item, true))
          res = false;
        if (filter.filterGroup?.ownerName && !item.owner_name.includes(filter?.filterGroup?.ownerName)) res = false;
        if (filter.filterGroup?.region?.value && filter?.filterGroup?.region?.value !== item.region) res = false;
        if (filter.filterGroup?.tenantName && !item.tenant_name.includes(filter?.filterGroup?.tenantName)) res = false;
        return res;
      });
    }),
  );

  @Output() copyDeploymentId = new EventEmitter<string>();
  @Output() downloadKey = new EventEmitter<Deployment>();
  @Output() refreshResources = new EventEmitter<Deployment>();
  @Output() startDeployment = new EventEmitter<Deployment>();
  @Output() stopDeployment = new EventEmitter<Deployment>();
  @Output() viewDeployment = new EventEmitter<Deployment>();
  @Output() deleteDeployment = new EventEmitter<Deployment>();
  @Output() loadDeleted = new EventEmitter<void>();
  @Output() updateDeployment = new EventEmitter<Deployment>();
  @Output() hydrateDeployment = new EventEmitter<Deployment>();
  @Output() getPasswordforDeployment = new EventEmitter<Deployment>();

  constructor(
    public tableService: TableService,
    public sharedService: SharedService,
    private deploymentsService: DeploymentsService,
  ) {}

  tableConfig: TableConfig = {
    key: 'deployments-table',
    header: {
      title: 'Deployments Overview',
    },
    noDataMessage: 'No deployments',
    useSearch: true,
    searchConfig: {
      placeholderLabel: 'Search',
    },
    onRowClick: (item: Deployment) => this.viewDeployment.emit(item),
    cssTRClassFromRow: TableEnhancedIdService.setTableRowIdColumn('deploymentname'),
    sort: {
      sortFunction: (event: Sort, data: any) => {
        return data.sort((a: any, b: any) => {
          const isAsc = event.direction === 'asc';
          switch (event.active) {
            case 'deploymentname':
              return this.tableService.compareAlphaNumeric(a.deploymentname, b.deploymentname, isAsc);
            case 'owner_name':
              return this.tableService.compareAlphaNumeric(a.owner_name, b.owner_name, isAsc);
            case 'tenant_name':
              return this.tableService.compareAlphaNumeric(a.tenant_name, b.tenant_name, isAsc);
            case 'isUserExpired':
              return this.tableService.compareAlphaNumeric(a.isUserExpired, b.isUserExpired, isAsc);
            case 'deploymenttype':
              return this.tableService.compareAlphaNumeric(a.deploymenttype, b.deploymenttype, isAsc);
            case 'status':
              return this.tableService.compareAlphaNumeric(a.status, b.status, isAsc);
            case 'region':
              return this.tableService.compareAlphaNumeric(a.region, b.region, isAsc);
            case 'subscriptionid':
              return this.tableService.compareAlphaNumeric(a.subscriptionid, b.subscriptionid, isAsc);
            case 'created_at':
              return this.sharedService.compareDates(a.created_at, b.created_at, isAsc);
            default:
              return 0;
          }
        });
      },
    },
    columns: [
      {
        id: 'deploymentname',
        key: 'deploymentname',
        title: 'Deployment Name',
        sortable: true,
        cellDisplay: {
          preset: 'link',
          template: '<span id="test">deploymentname</span>',
          link: {
            callback: (_event, item: Deployment) => this.viewDeployment.emit(item),
            text: (item: Deployment) => item.deploymentname,
          },
          model: 'deploymentname',
        },
      },
      {
        id: 'tenant_name',
        key: 'tenant_name',
        title: 'Tenant Name',
        sortable: true,
        cellDisplay: {
          model: 'tenant_name',
        },
      },
      {
        id: 'owner_name',
        key: 'owner_name',
        title: 'Owner Name',
        sortable: true,
        cellDisplay: {
          model: 'owner_name',
        },
      },
      {
        id: 'isUserExpired',
        key: 'isUserExpired',
        title: 'Owner Status',
        sortable: true,
        cellDisplay: {
          model: 'isUserExpired',
          preset: 'actionsIcons',
          actionsIcons: {
            iconsOrder: ['disabled'],
            cssIconContainerClass: 'svg-red',
            disabled: {
              id: 'disabled',
              tooltip: { text: 'Disabled user' },
              customSvgIcon: 'cancel',
              hidden: row => row.isUserExpired === false,
            },
          },
        },
      },
      SharedUtils.getTableColumn('deploymentid', 'Deployment ID'),
      {
        id: 'copy',
        key: 'copy',
        cssTDClass: 'copyRow',
        cellDisplay: {
          preset: 'actionsIcons',
          actionsIcons: {
            iconsOrder: ['copy'],
            copy: {
              id: 'copyId',
              tooltip: { text: 'Copy Deployment ID' },
              customSvgIcon: 'content_copy',
              callback: (_event, item: Deployment) => this.copyDeploymentId.emit(item.deploymentid),
            },
          },
        },
      },
      {
        id: 'deploymenttype',
        key: 'deploymenttype',
        title: 'Service',
        sortable: true,
        cellDisplay: {
          model: 'deploymenttype',
        },
      },
      {
        id: 'status',
        key: 'status',
        title: 'Status',
        sortable: true,
        cellDisplay: {
          getDisplay: (row: Deployment) => this.getStatus(row),
        },
      },
      {
        id: 'region',
        key: 'region',
        title: 'Region',
        sortable: true,
        cellDisplay: {
          getDisplay: (row: Deployment) => this.getStatus(row),
          model: 'region',
        },
      },
      {
        id: 'subscriptionStatus',
        key: 'subscriptionStatus',
        title: 'Subscription Status',
        sortable: true,
        cellDisplay: {
          model: 'subscriptionid',
          preset: 'actionsIcons',
          actionsIcons: {
            iconsOrder: [
              'trialInactive',
              'trialActive',
              'commercialInactive',
              'commercialActive',
              'legacyInactive',
              'legacyActive',
              'none',
            ],
            trialActive: {
              id: 'trialActive',
              tooltip: { text: 'Active Trial Subscription' },
              customSvgIcon: 'alarm',
              hidden: row => row.subscriptionStatus !== 'trialActive',
            },
            commercialActive: {
              id: 'commercialActive',
              tooltip: { text: 'Active Commercial Subscription' },
              customSvgIcon: 'commercial',
              hidden: row => row.subscriptionStatus !== 'commercialActive',
            },
            commercialInactive: {
              id: 'commercialInactive',
              tooltip: { text: 'Inactive Commercial Subscription' },
              customSvgIcon: 'commercial-red',
              hidden: row => row.subscriptionStatus !== 'commercialInactive',
            },
            trialInactive: {
              id: 'trialInactive',
              tooltip: { text: 'Expired Trial Subscription' },
              customSvgIcon: 'alarm-red',
              hidden: row => row.subscriptionStatus !== 'trialInactive',
            },
            legacyActive: {
              id: 'legacyActive',
              tooltip: { text: 'Active Legacy Subscription' },
              customSvgIcon: 'done',
              hidden: row => row.subscriptionStatus !== 'legacyActive',
            },
            none: {
              id: 'none',
              tooltip: { text: 'No Subscription' },
              customSvgIcon: 'cancel-red',
              hidden: row => row.subscriptionStatus !== undefined,
            },
            legacyInactive: {
              id: 'none',
              tooltip: { text: 'Inactive Legacy Subscription' },
              customSvgIcon: 'cancel-red',
              hidden: row => row.subscriptionStatus !== 'legacyInactive',
            },
          },
        },
      },
      {
        id: 'created_at',
        key: 'created_at',
        title: 'Created At',
        sortable: true,
        cellDisplay: {
          model: 'created_at',
        },
      },
      {
        id: 'actions',
        key: 'actions',
        title: 'Actions',
        cellDisplay: {
          preset: 'actionsIcons',
          actionsIcons: {
            iconsOrder: ['refresh', 'downloadKey', 'delete', 'start', 'stop', 'hydrate', 'password'],
            refresh: {
              id: 'refresh',
              tooltip: { text: 'Refresh Resources' },
              customSvgIcon: 'refresh',
              callback: (_event, item: Deployment) => this.refreshResources.emit(item),
              hidden: (item: Deployment) => this.deploymentsService.getOfferArchitecture(item.deploymenttype) == 'icca',
            },
            start: {
              id: 'start',
              tooltip: { text: 'Start Deployment' },
              customSvgIcon: 'play_arrow',
              callback: (_event, item: Deployment) => this.startDeployment.emit(item),
              disabled: (item: Deployment) => item.service_status == MamStatusEnum.Starting,
              hidden: (item: Deployment) =>
                !(
                  item.status == 'COMPLETE' &&
                  item.deploymenttype == 'fhiraas' &&
                  [MamStatusEnum.Stopped, MamStatusEnum.Starting].includes(item.service_status as MamStatusEnum)
                ),
            },
            stop: {
              id: 'stop',
              tooltip: { text: 'Stop Deployment' },
              customSvgIcon: 'stop',
              callback: (_event, item: Deployment) => this.stopDeployment.emit(item),
              disabled: (item: Deployment) => item.service_status == MamStatusEnum.Stopping,
              hidden: (item: Deployment) =>
                !(
                  item.status == 'COMPLETE' &&
                  item.deploymenttype == 'fhiraas' &&
                  [MamStatusEnum.Running, MamStatusEnum.Stopping].includes(item.service_status as MamStatusEnum)
                ),
            },
            downloadKey: {
              id: 'downloadKey',
              tooltip: { text: 'Download Key' },
              customSvgIcon: 'vpn_key',
              callback: (_event, item: Deployment) => this.downloadKey.emit(item),
              hidden: (item: Deployment) =>
                item.status == 'DELETED' || this.deploymentsService.getOfferArchitecture(item.deploymenttype) == 'icca',
            },
            hydrate: {
              id: 'hydrate',
              tooltip: { text: 'Hydrate' },
              customSvgIcon: 'hydrate',
              callback: (_event, item: Deployment) => this.hydrateDeployment.emit(item),
              hidden: (item: Deployment) =>
                !(item.status == 'COMPLETE' && ['hcc', 'hmts', 'ics'].indexOf(item.deploymenttype) != -1),
            },
            password: {
              id: 'password',
              tooltip: { text: 'Password' },
              customSvgIcon: 'password',
              callback: (_event, item: Deployment) => this.getPasswordforDeployment.emit(item),
              hidden: (item: Deployment) =>
                !(item.status == 'COMPLETE' && ['hcc', 'ics', 'hspdc'].indexOf(item.deploymenttype) != -1),
            },
            delete: {
              id: 'delete',
              tooltip: { text: 'Delete' },
              callback: (_event, item: Deployment) => this.deleteDeployment.emit(item),
              //hidden: (item: Deployment) => (['DELETING', 'DELETED'].includes(item.status) || (this.deploymentsService.getOfferArchitecture(item.deploymenttype) == 'icca' && environment.STAGE!='live')),
              hidden: (item: Deployment) => ['DELETING', 'DELETED'].includes(item.status),
            },
          },
        },
      },
    ],
  };

  paginatorConfig: PaginatorConfig = {
    pageSize: 10,
    pageSizeOptions: [10, 20, 50],
  };

  filterFDN: FDN = {
    name: '',
    description: '',
    validateOn: 'change',
    sectionLayout: { showSectionHeaders: false },
    sections: [
      {
        hideExpression: model => {
          this.filter$.next(model);
          return false;
        },
        fields: [
          {
            key: 'filterGroup',
            className: 'filterGroup',
            fieldGroup: [
              {
                id: 'service',
                key: 'service',
                type: 'select',
                templateOptions: {
                  label: 'Service',
                  options: [
                    {
                      name: 'hcc',
                      value: 'hcc',
                    },
                    {
                      name: 'hmts',
                      value: 'hmts',
                    },
                    {
                      name: 'hcaas',
                      value: 'hcaas',
                    },
                    {
                      name: 'fhiraas',
                      value: 'fhiraas',
                    },
                    {
                      name: 'sqlaas',
                      value: 'sqlaas',
                    },
                    {
                      name: 'lol',
                      value: 'lol',
                    },
                    {
                      name: 'doc',
                      value: 'doc',
                    },
                    {
                      name: 'fhirserver',
                      value: 'fhirserver',
                    },
                    {
                      name: 'sds',
                      value: 'sds',
                    },
                  ],
                },
                data: {
                  displayField: 'name',
                  uniqueValueField: 'value',
                  optionIdField: 'name',
                },
              },
              {
                id: 'status',
                key: 'status',
                type: 'select',
                templateOptions: {
                  label: 'Status',
                  options: [
                    {
                      name: 'COMPLETE',
                      value: 'COMPLETE',
                    },
                    {
                      name: 'DELETED',
                      value: 'DELETED',
                    },
                    {
                      name: 'FAILED_DELETE',
                      value: 'FAILED_DELETE',
                    },
                    {
                      name: 'N/A',
                      value: 'N/A',
                    },
                    {
                      name: 'PENDING',
                      value: 'PENDING',
                    },
                  ],
                },
                data: {
                  displayField: 'name',
                  uniqueValueField: 'value',
                  optionIdField: 'name',
                },
              },
              {
                id: 'ownerName',
                key: 'ownerName',
                type: 'input',
                templateOptions: {
                  label: 'Owner Name',
                },
              },
              {
                id: 'tenantName',
                key: 'tenantName',
                type: 'input',
                templateOptions: {
                  label: 'Tenant Name',
                },
              },
              {
                id: 'region',
                key: 'region',
                type: 'select',
                templateOptions: {
                  label: 'Region',
                  options: [
                    {
                      name: 'il-central-1',
                      value: 'il-central-1',
                    },
                    {
                      name: 'eu-central-1',
                      value: 'eu-central-1',
                    },
                    {
                      name: 'ca-central-1',
                      value: 'ca-central-1',
                    },
                    {
                      name: 'eu-west-2',
                      value: 'eu-west-2',
                    },
                    {
                      name: 'eu-west-1',
                      value: 'eu-west-1',
                    },
                    {
                      name: 'us-west-2',
                      value: 'us-west-2',
                    },
                    {
                      name: 'eu-west-3',
                      value: 'eu-west-3',
                    },
                    {
                      name: 'us-east-1',
                      value: 'us-east-1',
                    },
                    {
                      name: 'us-east-2',
                      value: 'us-east-2',
                    },
                  ],
                },
                data: {
                  displayField: 'name',
                  uniqueValueField: 'value',
                  optionIdField: 'name',
                },
              },
            ],
          },
        ],
      },
    ],
  };

  getStatus(row: Deployment, forFilter = false): string {
    if (!forFilter && row.deploymenttype == Deploymenttype.Fhiraas && row.status == Status.Complete)
      return row.service_status as string;

    if (this.deploymentsService.getOfferArchitecture(row.deploymenttype) == 'icca' && row.status != 'DELETED') {
      return 'N/A';
    }

    return row.status;
  }

  getSubscription(row): boolean {
    return row.subscriptionid;
  }
}
