import { Component } from '@angular/core';
import { AdminService } from 'admin-api';
import { tap } from 'rxjs/operators';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AdministrationService } from '../administration.service';
import { EditExpiresEvent } from './performanceprofiles-table/performanceprofiles-table.component';

@Component({
  selector: 'app-performanceprofiles-page',
  templateUrl: './performanceprofiles-page.component.html',
  styleUrls: ['./performanceprofiles-page.component.scss'],
})
export class PerformanceProfilesPageComponent {
  constructor(
    private administrationService: AdministrationService,
    private adminService: AdminService,
    private sharedService: SharedService,
  ) {}
  performanceProfiles$ = this.adminService.getPerformanceProfiles();
  editExpires(event: EditExpiresEvent) {
    this.administrationService
      .patchUser$(event.username, { expires_at: event.expires_at })
      .pipe(
        tap(user =>
          this.sharedService.showSuccess(
            'You have successfully changed expiration date of ' + user.username + ' to ' + user.expires_at,
          ),
        ),
        tap(() => this.sharedService.reloadCurrentRoute()),
      )
      .subscribe();
  }
}
