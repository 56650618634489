import { Component } from '@angular/core';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AdministrationService } from '../administration.service';

@Component({
  selector: 'app-sysinfo-page',
  templateUrl: './sysinfo-page.component.html',
  styleUrls: ['./sysinfo-page.component.scss'],
})
export class SysinfoPageComponent {
  constructor(private administrationService: AdministrationService, private sharedService: SharedService) {}
  versionData$ = this.administrationService.getVersionData$();
  launchResources$ = this.administrationService.getLaunchResources$();
  _codes;
  codes$ = this.administrationService.getCodes$().pipe(tap(result => (this._codes = result.codes)));

  downloadCodes() {
    let codeBlob = '';
    this._codes.forEach(function (code) {
      codeBlob += window.location.origin + '/account/signup?code=' + code.code + '\n';
    });
    const fileURL = window.URL.createObjectURL(new Blob([codeBlob]));
    const fileLink = document.createElement('a');
    const fileName = 'sign_up_codes.csv';
    fileLink.href = fileURL;
    fileLink.setAttribute('download', fileName);
    document.body.appendChild(fileLink);
    fileLink.click();
  }

  generateCodes() {
    this.administrationService
      .addCodes$(10)
      .pipe(
        tap(() => this.sharedService.showSuccess('Generated 10 new codes')),
        tap(() => this.sharedService.reloadCurrentRoute()),
        catchError(() => of(this.sharedService.showAlert('Error generating codes '))),
      )
      .subscribe();
  }
}
