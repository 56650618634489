import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaginatorConfig, TableConfig, TableService } from '@intersystems/table';
import { User } from 'src/app/core/interfaces/user';
import { TableEnhancedIdService } from 'src/app/core/table-enhanced-id.service';
import { SharedUtils } from 'src/app/shared/shared-utils';
import { Sort } from '@angular/material/sort';
import { SharedService } from '../../../shared/services/shared.service';

export interface EditExpiresEvent {
  username: string;
  expires_at: string | boolean;
}

@Component({
  selector: 'app-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.scss'],
})
export class UsersTableComponent {
  @Input() users!: User[];
  @Output() editExpires = new EventEmitter<EditExpiresEvent>();

  constructor(public tableService: TableService, public sharedService: SharedService) {}

  tableConfig: TableConfig = {
    key: 'users-table',
    header: {
      title: 'Users Overview',
    },
    noDataMessage: 'No users',
    useSearch: true,
    searchConfig: {
      placeholderLabel: 'Search',
    },
    cssTRClassFromRow: TableEnhancedIdService.setTableRowIdColumn('username'),
    sort: {
      sortFunction: (event: Sort, data: any) => {
        return data.sort((a: any, b: any) => {
          const isAsc = event.direction === 'asc';
          switch (event.active) {
            case 'username':
              return this.tableService.compareAlphaNumeric(a.username, b.username, isAsc);
            case 'created_at':
              return this.sharedService.compareDates(a.created_at, b.created_at, isAsc);
            case 'expires_at':
              return this.sharedService.compareDates(a.expires_at, b.expires_at, isAsc);
            case 'is_admin':
              return this.tableService.compareAlphaNumeric(a.is_admin, b.is_admin, isAsc);
            default:
              return 0;
          }
        });
      },
    },
    columns: [
      {
        id: 'username',
        key: 'username',
        title: 'Username',
        sortable: true,
        cellDisplay: {
          model: 'username',
        },
      },
      {
        id: 'fullName',
        key: 'fullName',
        title: 'Full Name',
        cellDisplay: {
          getDisplay: (row: User) => row.first_name + ' ' + row.last_name,
        },
      },
      {
        id: 'subscriptionid',
        key: 'subscriptionid',
        title: 'Subscription Id',
        cellDisplay: {
          model: 'subscriptionid',
        },
      },
      SharedUtils.getTableColumn('email'),
      {
        id: 'created_at',
        key: 'created_at',
        title: 'Created At',
        sortable: true,
        cellDisplay: {
          model: 'created_at',
        },
      },
      {
        id: 'expires_at',
        key: 'expires_at',
        title: 'Expires',
        sortable: true,
        cellDisplay: {
          preset: 'editable',
          model: 'expires_at',
          editable: {
            model: 'expires_at',
            editAs: 'input',
            onEdit: row => this.onExpiresEdit(row),
          },
        },
      },
      {
        id: 'is_admin',
        key: 'is_admin',
        title: 'Admin',
        sortable: true,
        cellDisplay: {
          model: 'is_admin',
        },
      },
    ],
  };

  paginatorConfig: PaginatorConfig = {
    pageSize: 10,
    pageSizeOptions: [10, 20, 50],
  };

  onExpiresEdit(row: User) {
    this.editExpires.emit({ username: row.username, expires_at: row.expires_at });
  }
}
