export interface Deployment {
  access_list?: any[];
  account?: string;
  bank_config: boolean;
  cloudprovider?: Cloudprovider;
  created_at?: string;
  deleted_at?: boolean | string;
  deploymentid?: string;
  deploymentname?: string;
  deploymentsize?: Deploymentsize;
  deploymenttype?: Deploymenttype;
  ecp_config?: boolean;
  ha_config?: HaConfig;
  is_commercial?: boolean;
  is_spot?: boolean;
  item_type?: ItemType;
  mam_status?: boolean | MamStatusEnum;
  offer_name?: boolean;
  owner_name?: string;
  tenant_name?: string;
  region?: Region;
  resource_list?: any[] | { [key: string]: string };
  resource_role?: ResourceRole;
  service_status?: boolean | MamStatusEnum;
  stackname?: string;
  status?: Status;
  storage_last_notify_date?: boolean | string;
  subscriptionid?: boolean | string;
  tenantid?: string;
  updated_at?: string;
  instance_status_list?: any[];
}

export enum Cloudprovider {
  Aws = 'aws',
  Azure = 'azure'
}

export enum Deploymentsize {
  Demo = 'demo',
  Micro = 'micro',
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
  Xlarge = 'xlarge',
}

export enum Deploymenttype {
  Fhiraas = 'fhiraas',
  HCC = 'hcc',
  Hmts = 'hmts',
  Sqlaas = 'sqlaas',
  SDS = 'sds',
  Lol = 'lol',
  SPC = 'spc',
  Doc = 'doc',
  FHIRServer = 'fhirserver',
  HSPD = 'hspdc',
}

export enum HaConfig {
  Mirrored = 'mirrored',
  NonMirrored = 'non-mirrored',
}

export enum ItemType {
  Deployment = 'Deployment',
}

export enum MamStatusEnum {
  Running = 'RUNNING',
  Starting = 'STARTING',
  Stopped = 'STOPPED',
  Stopping = 'STOPPING',
}

export enum Region {
  EuCentral1 = 'eu-central-1',
  EuWest2 = 'eu-west-2',
  UsEast1 = 'us-east-1',
  UsEast2 = 'us-east-2',
}

export enum ResourceRole {
  SharedCrossAccountTest = 'SharedCrossAccountTest',
}

export enum Status {
  Complete = 'COMPLETE',
  Deleted = 'DELETED',
}
