import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Auth } from '@aws-amplify/auth';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { NotificationService } from '@intersystems/notification';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { Configuration, UsersService } from 'api';
import { HttpClient, HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { TopbarControlService } from '@intersystems/header';
import { User } from 'api';
import { TimeoutService, TimeoutPolicy, TimeoutConfig } from '@intersystems/timeout';
import { Router } from '@angular/router';
import { SharedService } from '../shared/services/shared.service';
import _ from 'lodash';

const awsConfig = {
  Auth: {
    region: environment.NG_APP_COGNITO_REGION,
    userPoolId: environment.NG_APP_COGNITO_USERPOOL_ID,
    userPoolWebClientId: environment.NG_APP_COGNITO_CLIENT_ID,
  },
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private notificationService: NotificationService,
    private usersService: UsersService,
    private topBarControlSvc: TopbarControlService,
    private http: HttpClient,
    private router: Router,
    private timeoutService: TimeoutService,
    private sharedService: SharedService,
  ) {
    // Configure AWS Cognito
    Auth.configure(awsConfig);
  }

  private _user = new BehaviorSubject<User>(null);
  user$ = this._user.asObservable();
  get user(): User {
    return this._user.getValue();
  }

  private _currentTenantId = new BehaviorSubject<string>(localStorage.getItem('currentTenantId'));
  currentTenantId$ = this._currentTenantId.asObservable();
  get currentTenantId(): string {
    return this.getTenantId();
  }

  authorise(username: string, password: string, totpCode?: string): Observable<string> {
    let getUserName$: Observable<any>;
    if (username) {
      // Authorise in AWS Cognito
      localStorage.removeItem('currentTenantId');
      getUserName$ = from(Auth.signIn(username, password));
    } else {
      // Already authorised, get user name from cookie
      getUserName$ = from(Auth.currentUserInfo()).pipe(catchError(() => of(null)));
    }
    return getUserName$.pipe(
      switchMap(user =>
        totpCode
          ? from(Auth.confirmSignIn(user, totpCode, 'SOFTWARE_TOKEN_MFA')).pipe(
              switchMap(() => from(Auth.currentUserInfo())),
            )
          : of(user),
      ),
      map(
        userJSON => {
          if (userJSON) {
            if (userJSON.challengeName == 'SOFTWARE_TOKEN_MFA') {
              throw 'SOFTWARE_TOKEN_MFA';
            }
            window.localStorage.setItem('user', JSON.stringify(userJSON.username));
            return userJSON.username;
          } else {
            return '';
          }
        },
        (error: { message: any }) => {
          this._user.next(null);
          if (username) this.notificationService.showAlert(`Sign in failed`, 7000);
          return '';
        },
      ),
      // Stop if user is empty
      filter(user => !!user),
      // Link to a subscription if we got a subscription cookie
      switchMap(username => this.linkSubscription(username)),
      // Get user info
      switchMap(username => this.usersService.getUserByUsername(username)),
      // Initiate session timeout
      tap(() => this.initialiseSessionTimeout()),
      // Set user info and redirect to deployments page
      tap((user: any) => {
        if (user) {
          let currentTenantId = undefined;
          const currentSelectedTenantsState = JSON.parse(localStorage.getItem('selectedTenantsState'));

          // set last selected tenant as current if user has one
          if (currentSelectedTenantsState && currentSelectedTenantsState[user.username]) {
            currentTenantId = currentSelectedTenantsState[user.username] as string;
          }

          // when current tenant hasn't been set, or no longer exists in user tenants
          if (!currentTenantId || !(currentTenantId in user.tenants)) {
            const tenants = _.map((user as User).tenants, (tenant, tenantId) => ({ tenantId, tenant }));

            // first try and find most recently joined tenant
            currentTenantId = _.chain(tenants)
              // filter where tenant timestamp has a value
              .filter(t => _.isString(t.tenant.created_at))
              // sort by timestamp in ascending order
              .sortBy(t => Date.parse(t.tenant.created_at))
              // decending order, most recently joined tenants at the top
              .reverse()
              .map(t => t.tenantId)
              .head()
              .value();

            // else use tenant marked as "default"
            if (!currentTenantId) {
              currentTenantId = Object.keys(user.tenants).find(tenantid => user.tenants[tenantid].default);
              if (!currentTenantId) {
                currentTenantId = Object.keys(user.tenants)[0];
              }
            }
          }

          this.setCurrentTenantId(currentTenantId);

          return this._user.next(user);
        }
      }),
      catchError(e => {
        if (e == 'SOFTWARE_TOKEN_MFA') return of('SOFTWARE_TOKEN_MFA');
        this.notificationService.showAlert(`Sign in failed`, 7000);
        return this.logOut(false).pipe(map(() => null));
      }),
    );
  }

  /// Used in app.module.ts to set up APIs
  static getApiConfiguration(): Configuration {
    return new Configuration({ basePath: environment.API_URL });
  }

  /// Add an authentication token header from Cognito
  addAuthHeader$(req: HttpRequest<any>): Observable<HttpRequest<any>> {
    return from(Auth.currentSession()).pipe(
      map(session => {
        const authToken = session.getIdToken().getJwtToken();
        // Clone the request and replace the original headers with
        // cloned headers, updated with the authorization.
        const authReq = req.clone({
          headers: req.headers.set('Authorization', authToken),
        });
        return authReq;
      }),
    );
  }

  /// Check if user is authenticated
  check$(): Observable<boolean> {
    return from(Auth.currentSession()).pipe(
      // Session is valid - return true
      map(() => true),
      // Session is invalid - return false
      catchError(() => {
        this.logOut(false);
        return of(false);
      }),
    );
  }

  linkSubscription(username: string): Observable<string> {
    // Check for a cookie named 'portaldata' which will be set by AWS
    // Return an observable of username
    try {
      if (document.cookie.indexOf('portaldata=') == -1) return of(username);
      if (!username) return of(username);
      const cookieValue = document.cookie
        .split('; ')
        .find(row => row.startsWith('portaldata='))
        .split('=')[1];
      if (!cookieValue) return of(username);
      // Expire the cookie
      document.cookie = 'portaldata=; domain=isccloud.io; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

      const body = {
        username: username,
        cookie: cookieValue,
      };
      const subscriptionsURL = environment.API_URL + '/subscriptions/aws';
      return this.http.post(subscriptionsURL, body).pipe(
        tap(() => this.sharedService.showSuccess('Subscription successfully created')),
        map(() => username),
        catchError((e: HttpErrorResponse) => {
          if (e.status == 400) {
            this.sharedService.showAlert(
              `Error creating subscription. ${
                e.error.details ? e.error.details : ''
              } You will not be charged for this failed subscription`,
              true,
            );
          }
          if (e.status == 500) {
            this.sharedService.showAlert(
              `Internal server error while creating subscription. Please contact an InterSystems Cloud Team member for assistance. You will not be charged for this failed subscription.`,
              true,
            );
          }
          return of(username);
        }),
      );
    } catch (e) {
      this.sharedService.showAlert(
        `Internal server error while creating subscription. Please contact an InterSystems Cloud Team member for assistance. You will not be charged for this failed subscription.`,
        true,
      );
      return of(username);
    }
  }

  signUp(username: string, password: string, email: string, code: string, firstName: string, lastName: string) {
    return from(
      Auth.signUp({
        username,
        password,
        attributes: {
          email,
        },
        validationData: {
          code,
          firstName,
          lastName,
        },
      }),
    );
  }

  confirmSignUp(username: string, code: string) {
    return from(Auth.confirmSignUp(username, code));
  }

  resendCode(username: string) {
    return from(Auth.resendSignUp(username));
  }

  sendForgotPasswordCode(username: string) {
    return from(Auth.forgotPassword(username));
  }

  resetPassword(username: string, code: string, password: string) {
    return from(Auth.forgotPasswordSubmit(username, code, password));
  }

  updatePassword(oldPassword: string, password: string) {
    return from(Auth.currentAuthenticatedUser()).pipe(
      switchMap(user => from(Auth.changePassword(user, oldPassword, password))),
    );
  }

  initialiseSessionTimeout() {
    if (!SharedService.isLive()) return;
    const timeoutConfig: TimeoutConfig = {
      onLogoutCallback: () => this.logOut(true),
      onTimeoutCallback: () => this.logOut(true),
      warnTime: 120,
      warningModalConfig: {
        title: 'InterSystems Cloud Portal',
      },
    };
    this.timeoutService.configureTimeoutService(timeoutConfig);
    const timeoutPolicy: TimeoutPolicy = {
      timeoutLength: 1800,
    };
    this.timeoutService.stopTimeoutWatch();
    this.timeoutService.initTimeoutWatch(timeoutPolicy);
  }

  logOut(redirect: boolean) {
    localStorage.removeItem('currentTenantId');
    this._currentTenantId.next(null);
    this.topBarControlSvc.setUsernameData({});
    this._user.next(null);
    this.timeoutService.stopTimeoutWatch();
    if (redirect) this.router.navigate(['/account/login']);
    return from(Auth.signOut());
  }

  setCurrentTenantId(tenantId?: string): void {
    if (!localStorage.getItem('currentTenantId') && !tenantId) {
      const tenants = this._user?.getValue().tenants;
      if (!tenants) {
        tenantId = '';
      } else {
        for (const [id, value] of Object.entries(tenants)) {
          if (value.default === true) {
            tenantId = id;
          }
        }
      }
    }
    if (localStorage.getItem('currentTenantId') && !tenantId) {
      tenantId = localStorage.getItem('currentTenantId');
    }
    localStorage.setItem('currentTenantId', tenantId);
    this._currentTenantId.next(tenantId);
    this.updateTenantsState();
  }

  updateTenantsState(): void {
    if (!this.user) return;
    const userName = this.user.username;
    const currentTenantId = localStorage.getItem('currentTenantId');
    const currentSelectedTenantsState = JSON.parse(localStorage.getItem('selectedTenantsState'));

    const newSelectedTenantsState = {
      ...currentSelectedTenantsState,
      [userName]: currentTenantId,
    };

    localStorage.setItem('selectedTenantsState', JSON.stringify(newSelectedTenantsState));
  }

  onLeaveTenant(tenantId: string): void {
    if (this._currentTenantId.value == tenantId) {
      localStorage.removeItem('currentTenantId');
      this.setCurrentTenantId();
    }
  }

  getTenantId(): string {
    return this._currentTenantId.value;
  }

  getCurrentTenantData(): any {
    const tenants = this._user?.getValue().tenants;
    const currentTenantId = this.getTenantId();

    if (tenants) {
      for (const [tenantId, value] of Object.entries(tenants)) {
        if (currentTenantId === tenantId) {
          return value;
        }
      }
    }

    return '';
  }

  submitFeedback(appContext = '', feedback) {
    const url = environment.API_URL + '/notifications';
    const user: User = this.user;

    const body = {
      firstName: user?.first_name,
      lastName: user?.last_name,
      username: user?.username,
      email: user?.email,
      appContext: appContext,
      errorDescription: feedback,
      url: window.location.href,
    };

    return this.http.post(url, body);
  }
}
