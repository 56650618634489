import { Component } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AdministrationService } from '../administration.service';
import { EditExpiresEvent } from './users-table/users-table.component';

@Component({
  selector: 'app-users-page',
  templateUrl: './users-page.component.html',
  styleUrls: ['./users-page.component.scss'],
})
export class UsersPageComponent {
  constructor(private administrationService: AdministrationService, private sharedService: SharedService) {}

  users$ = this.administrationService.users$().pipe(
    map(users => {
      users.forEach(u => {
        if (u.subscriptions) {
          Object.values(u.subscriptions).forEach(v => {
            if (v.provider === 'aws') u.subscriptionid = v.subscriptionid;
          });
        }
      });

      return users;
    }),
    tap(users => users.forEach(user => (user.expires_at == false ? (user.expires_at = 'false') : ''))),
  );

  editExpires(event: EditExpiresEvent) {
    this.administrationService
      .patchUser$(event.username, { expires_at: event.expires_at == 'false' ? '' : event.expires_at })
      .pipe(
        tap(user =>
          this.sharedService.showSuccess(
            'You have successfully changed expiration date of ' + user.username + ' to ' + user.expires_at,
          ),
        ),
        tap(() => this.sharedService.reloadCurrentRoute()),
      )
      .subscribe();
  }
}
