<mat-drawer-container class="drawer-container" autosize=true hasBackdrop="true" (backdropClick)="trainDrawer.close()">

  <mat-drawer-content class="drawer-content">

    <mat-card>
      <mat-card-header>
        <mat-card-title>IntegratedML
          <app-info-button [htmlText]="infoObject.title.htmlText" [title]="infoObject.title.infoTitle">
          </app-info-button>
        </mat-card-title>

      </mat-card-header>

      <div>
        <mat-accordion id="mat-acc-create-panel">
          <mat-expansion-panel id="mat-exp-create-panel" (opened)="imlService.imlFormData.create.opened = true"
            (closed)="imlService.imlFormData.create.opened = false" [expanded]="imlService.imlFormData.create.opened">
            <mat-expansion-panel-header id="mat-exp-header-create-panel">
              <mat-panel-title id="mat-exp-title-create-panel">
                Create
                <app-info-button [htmlText]="infoObject.create.htmlText" [title]="infoObject.create.infoTitle">
                </app-info-button>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <mat-card>
              <table>
                <tr>
                  <td>
                    <mat-label class="field-label" id="model-name-label">Model name
                    </mat-label>
                    <mat-form-field id="model-name-field">
                      <input [(ngModel)]="imlService.imlFormData.create.modelName" id="model-name-input" matInput
                        type="text" pattern="^[a-zA-Z]+([a-zA-Z0-9]+)*$"
                        [value]="imlService.imlFormData.create.modelName">
                      <mat-error>
                        Invalid name
                      </mat-error>
                    </mat-form-field>
                  </td>
                  <td>
                    <app-info-button [htmlText]="infoObject.createModel.htmlText"
                      [title]="infoObject.createModel.infoTitle">
                    </app-info-button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <mat-label class="field-label" id="model-field-label">Table to train model on
                    </mat-label>

                    <mat-form-field id="table-select-field">
                      <mat-select id="table-select" [(ngModel)]="imlService.imlFormData.create.selectedTable">
                        <mat-option *ngFor="let table of tableData" [value]="table.toString().replace(',','.')"
                          (onSelectionChange)="tableSelected($event)" [id]="table.toString().replace(',','.')">
                          {{table.toString().replace(',','.')}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </td>
                  <td>
                    <app-info-button [htmlText]="infoObject.createTable.htmlText"
                      [title]="infoObject.createTable.infoTitle">
                    </app-info-button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <mat-label class="field-label" id="model-predict-label">Field to predict
                    </mat-label>
                    <mat-form-field id="field-select-field">
                      <mat-select id="field-select" [(ngModel)]="imlService.imlFormData.create.selectedCol">
                        <mat-option *ngFor="let col of colList" [value]="col.display"
                          (onSelectionChange)="colSelected($event)" [id]="col.name">
                          {{col.display}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </td>
                  <td>
                    <app-info-button [htmlText]="infoObject.createField.htmlText"
                      [title]="infoObject.createField.infoTitle">
                    </app-info-button>
                  </td>
                </tr>
              </table>

              <div>SQL to run:<br><br>&ensp;
                <div *ngIf="hideSqlCreateModel">Create model
                  <b>{{imlService.imlFormData.create.modelName}}</b> predicting
                  (<b>{{imlService.getDisplay(imlService.imlFormData.create.selectedCol,true)}}</b>) from
                  <b>{{imlService.imlFormData.create.selectedTable}}</b><br>
                  <br><br>
                </div>
                <textarea class="small-textarea" *ngIf="!hideSqlCreateModel" [(ngModel)]="sqlCreateModel"
                  id="sql-create-model" value={{sqlCreateModel}} [disabled]="(hideSqlCreateModel)" rows="4" cols="50">
                </textarea>
              </div>


              <mat-card-actions>
                <button id="btn-create-model" mat-flat-button class="fr-primary" color="primary" (click)="createModel()"
                  [disabled]="(hideSqlCreateModel && ((imlService.imlFormData.create.modelName=='')||(imlService.imlFormData.create.selectedTable=='')||(imlService.imlFormData.create.selectedCol=='')))">
                  Create model
                </button>
                <button id="btn-execute-created-editor" mat-flat-button class="fr-primary button-padding"
                  color="primary"
                  (click)="editInSQLEditor('create', 'Create model ' + imlService.imlFormData.create.modelName + ' predicting (' + imlService.getDisplay(imlService.imlFormData.create.selectedCol,true) + ') from ' + imlService.imlFormData.create.selectedTable)">
                  {{hideSqlCreateModel ? 'Edit SQL' : 'Revert'}}
                </button>
                <button id="btn-show-created-models" mat-flat-button class="fr-primary" color="primary"
                  (click)="createdModelsClick()">
                  Show created models
                </button>
                <app-info-button [htmlText]="infoObject.createButtons.htmlText"
                  [title]="infoObject.createButtons.infoTitle">
                </app-info-button>
              </mat-card-actions>
            </mat-card>
          </mat-expansion-panel>
        </mat-accordion>
      </div>

      <mat-accordion id="mat-acc-train-panel">
        <mat-expansion-panel id="mat-exp-train-panel" (opened)="imlService.imlFormData.train.opened = true"
          (closed)="imlService.imlFormData.train.opened = false" [expanded]="imlService.imlFormData.train.opened">
          <mat-expansion-panel-header id="mat-exp-header-train-panel">
            <mat-panel-title id="mat-exp-title-train-panel">
              Train
              <app-info-button [htmlText]="infoObject.train.htmlText" [title]="infoObject.train.infoTitle">
              </app-info-button>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-card>
            <table>
              <tr>
                <td>
                  <mat-label id="train-model-name-label">Model to train
                  </mat-label>

                  <mat-form-field id="model-select-field">
                    <mat-select id="model-select" [(ngModel)]="imlService.imlFormData.train.selectedModel">
                      <mat-option *ngFor="let model of modelData"
                        [value]="model.toString().substr(0,model.toString().lastIndexOf(',')).replace(',',' PREDICTING ')"
                        (onSelectionChange)="modelSelected($event)"
                        [id]="model.toString().substr(0,model.toString().lastIndexOf(',')).replace(',','.')">
                        {{model.toString().substr(0,model.toString().lastIndexOf(',')).replace(',',' PREDICTING ')}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
                <td>
                  <app-info-button [htmlText]="infoObject.trainModel.htmlText"
                    [title]="infoObject.trainModel.infoTitle">
                  </app-info-button>
                </td>
              </tr>
              <tr>
                <td>
                  <mat-label id="train-trained-model-name-label">Trained model name
                  </mat-label>
                  <mat-form-field id="trained-name-field">
                    <input #trainedName="ngModel" [(ngModel)]="imlService.imlFormData.train.trainedName"
                      id="trained-name-input" matInput type="text" pattern="^[a-zA-Z]+([a-zA-Z0-9_]+)*$"
                      [value]="imlService.imlFormData.train.trainedName">
                    <mat-error>
                      Invalid name
                    </mat-error>
                  </mat-form-field>
                </td>
                <td>
                  <app-info-button [htmlText]="infoObject.trainName.htmlText" [title]="infoObject.trainName.infoTitle">
                  </app-info-button>
                </td>
              </tr>
            </table>
            <div>SQL to run:<br><br>&ensp;
              <div *ngIf="hideSqlTrainModel">Train model
                <b>{{imlService.getDisplay(imlService.imlFormData.train.selectedModel)}}</b>
                <span *ngIf="(imlService.imlFormData.train.trainedName!='')"> as
                  <b>{{imlService.imlFormData.train.trainedName}}</b>
                </span>
                <br><br>
              </div>
              <textarea class="small-textarea" *ngIf="!hideSqlTrainModel" [(ngModel)]="sqlTrainModel"
                id="sql-train-model" value={{sqlTrainModel}} [disabled]="(hideSqlTrainModel)" rows="4" cols="50">
              </textarea>
            </div>
            <mat-card-actions>
              <button id="btn-train-model" mat-flat-button class="fr-primary" color="primary" (click)="trainModel()"
                [disabled]="hideSqlTrainModel && ((imlService.imlFormData.train.selectedModel=='')||(trainedName.errors))">
                Train model
              </button>
              <button id="btn-execute-train-editor" mat-flat-button class="fr-primary button-padding" color="primary"
                (click)="editInSQLEditor('train','Train model ' + imlService.getDisplay(imlService.imlFormData.train.selectedModel) + (imlService.imlFormData.train.trainedName=='' ? ' ' : ' as ' + imlService.imlFormData.train.trainedName))">
                {{hideSqlTrainModel ? 'Edit SQL' : 'Revert'}}
              </button>
              <button id="btn-training-runs" mat-flat-button class="fr-primary" color="primary"
                (click)="trainingRunsClick()">
                Show training runs
              </button>
              <button id="btn-show-trained-models" mat-flat-button class="fr-primary" color="primary"
                (click)="trainedModelsClick()">
                Show trained models
              </button>
              <app-info-button [htmlText]="infoObject.trainButtons.htmlText"
                [title]="infoObject.trainButtons.infoTitle">
              </app-info-button>
            </mat-card-actions>
          </mat-card>
        </mat-expansion-panel>
      </mat-accordion>

      <mat-accordion id="mat-acc-validate-panel">
        <mat-expansion-panel id="mat-exp-validate-panel" (opened)="imlService.imlFormData.validate.opened = true"
          (closed)="imlService.imlFormData.validate.opened = false" [expanded]="imlService.imlFormData.validate.opened">
          <mat-expansion-panel-header id="mat-exp-header-validate-panel">
            <mat-panel-title id="mat-exp-title-validate-panel">
              Validate
              <app-info-button [htmlText]="infoObject.validate.htmlText" [title]="infoObject.validate.infoTitle">
              </app-info-button>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-card>
            <table>
              <tr>
                <td>
                  <mat-label id="validate-trained-model-label">Trained model to validate

                  </mat-label>
                  <mat-form-field id="validate-model-select-field">
                    <mat-select id="validate-model-select" (openedChange)="trainedModelOpenChanged($event)"
                      [compareWith]="trainedModelCompareMethod"
                      [(ngModel)]="imlService.imlFormData.validate.modelToValidate">
                      <mat-option *ngFor="let model of trainedModelData" [value]="model"
                        (onSelectionChange)="validateModelSelected($event)" [id]="model.toString().replace(',','.')">
                        {{model.toString().replace(',',' using ')}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
                <td>
                  <app-info-button [htmlText]="infoObject.validateModel.htmlText"
                    [title]="infoObject.validateModel.infoTitle">
                  </app-info-button>
                </td>
              </tr>
              <tr>
                <td>
                  <mat-label class="field-label" id="validate-table-label">Table to validate model from
                  </mat-label>

                  <mat-form-field id="table-validate-field">
                    <mat-select id="table-validate"
                      [(ngModel)]="imlService.imlFormData.validate.selectedTableForValidation">
                      <mat-option *ngFor="let table of tableData" [value]="table.toString().replace(',','.')"
                        (onSelectionChange)="tableForValidationSelected($event)"
                        [id]="table.toString().replace(',','.')">
                        {{table.toString().replace(',','.')}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
                <td>
                  <app-info-button [htmlText]="infoObject.validateTable.htmlText"
                    [title]="infoObject.validateTable.infoTitle">
                  </app-info-button>
                </td>
              </tr>
              <tr>
                <td>
                  <mat-label class="field-label" id="validate-run-label">Validation run name
                  </mat-label>
                  <mat-form-field id="validate-name-field">
                    <input #validationName="ngModel" [(ngModel)]="imlService.imlFormData.validate.validationName"
                      id="validate-name-input" matInput type="text" pattern="^[a-zA-Z]+([a-zA-Z0-9_]+)*$"
                      [value]="imlService.imlFormData.validate.validationName">
                    <mat-error>
                      Invalid name
                    </mat-error>
                  </mat-form-field>
                </td>
                <td>
                  <app-info-button [htmlText]="infoObject.validateRun.htmlText"
                    [title]="infoObject.validateRun.infoTitle">
                  </app-info-button>
                </td>
              </tr>
            </table>

            <div>SQL to run:<br><br>&ensp;
              <div *ngIf="hideSqlValidateModel">Validate model
                <b>{{imlService.imlFormData.validate.selectedValidateModel}} </b>
                <span *ngIf="imlService.imlFormData.validate.validationName!=''">As
                  <b>{{imlService.imlFormData.validate.validationName}}</b> </span>
                use <b>{{imlService.imlFormData.validate.trainedModelToValidate}}</b>
                from <b>{{imlService.imlFormData.validate.selectedTableForValidation}}</b><br><br>

              </div>
              <textarea class="small-textarea" *ngIf="!hideSqlValidateModel" [(ngModel)]="sqlValidateModel"
                id="sql-validate-model" value={{sqlValidateModel}} [disabled]="(hideSqlValidateModel)" rows="4"
                cols="50">
              </textarea>
            </div>
            <mat-card-actions>
              <button id="btn-execute-validate-model" mat-flat-button class="fr-primary" color="primary"
                (click)="validateModel()"
                [disabled]="hideSqlValidateModel && ((imlService.imlFormData.validate.selectedValidateModel=='')||(validationName.errors))">
                Validate model
              </button>
              <button id="btn-execute-validate-editor" mat-flat-button class="fr-primary button-padding" color="primary"
                (click)="editInSQLEditor('validate', 'Validate model ' + imlService.imlFormData.validate.selectedValidateModel + (imlService.imlFormData.validate.validationName=='' ? ' ' : ' As ' + imlService.imlFormData.validate.validationName) + 'use ' + imlService.imlFormData.validate.trainedModelToValidate + ' from ' + imlService.imlFormData.validate.selectedTableForValidation)">
                {{hideSqlValidateModel ? 'Edit SQL' : 'Revert'}}
              </button>
              <button id="btn-validation-runs" mat-flat-button class="fr-primary" color="primary"
                (click)="validationRunsClick()">
                Show validation runs
              </button>
              <button id="btn-validation-metrics" mat-flat-button class="fr-primary" color="primary"
                (click)="validationMetricsClick()">
                Show validation metrics
              </button>
              <app-info-button [htmlText]="infoObject.validateButtons.htmlText"
                [title]="infoObject.validateButtons.infoTitle">
              </app-info-button>
            </mat-card-actions>
          </mat-card>
        </mat-expansion-panel>
      </mat-accordion>

      <mat-accordion id="mat-acc-predict-panel">
        <mat-expansion-panel id="mat-exp-predict-panel" (opened)="imlService.imlFormData.predict.opened = true"
          (closed)="imlService.imlFormData.predict.opened = false" [expanded]="imlService.imlFormData.predict.opened">
          <mat-expansion-panel-header id="mat-exp-header-predict-panel">
            <mat-panel-title id="mat-exp-title-predict-panel">
              Predict
              <app-info-button [htmlText]="infoObject.predict.htmlText" [title]="infoObject.predict.infoTitle">
              </app-info-button>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-card>
            <table>
              <tr>
                <td>
                  <mat-label id="predict-trained-model-label">Trained model to use as predictor
                  </mat-label>

                  <mat-form-field id="predict-model-select-field">
                    <mat-select id="predict-model-select" (openedChange)="trainedModelOpenChanged($event)"
                      [compareWith]="trainedModelCompareMethod"
                      [(ngModel)]="imlService.imlFormData.predict.modelToPredict">
                      <mat-option *ngFor="let model of trainedModelData" [value]="model.toString()"
                        (onSelectionChange)="predictModelSelected($event)" [id]="model.toString().replace(',','.')">
                        {{model.toString().replace(',',' using ')}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                </td>
                <td>
                  <app-info-button [htmlText]="infoObject.predictModel.htmlText"
                    [title]="infoObject.predictModel.infoTitle">
                  </app-info-button>
                </td>
              </tr>
              <tr>
                <td>
                  <mat-label class="field-label" id="predict-table-label">Table containing input fields
                  </mat-label>

                  <mat-form-field id="predict-table-select-field">
                    <mat-select id="predict-table-select"
                      [(ngModel)]="imlService.imlFormData.predict.predictSelectedTable">
                      <mat-option *ngFor="let table of tableData" [value]="table.toString().replace(',','.')"
                        (onSelectionChange)="predictTableSelected($event)" [id]="table.toString().replace(',','.')">
                        {{table.toString().replace(',','.')}}
                      </mat-option>
                    </mat-select>

                  </mat-form-field>
                </td>
                <td>
                  <app-info-button [htmlText]="infoObject.predictTable.htmlText"
                    [title]="infoObject.predictTable.infoTitle">
                  </app-info-button>
                </td>
              </tr>
              <tr>
                <td>
                  <mat-label id="predict-rows-label">Number of rows to predict, from top</mat-label>
                  <mat-form-field id="top-rows-field">
                    <input id="top-rows-input" [(ngModel)]="imlService.imlFormData.predict.predictTop" matInput
                      type="number" [value]="imlService.imlFormData.predict.predictTop">
                  </mat-form-field>
                </td>
                <td>
                  <app-info-button [htmlText]="infoObject.predictTop.htmlText"
                    [title]="infoObject.predictTop.infoTitle">
                  </app-info-button>
                </td>
              </tr>
            </table>
            <mat-card-actions>
              <button id="btn-generate-sql" mat-flat-button class="fr-primary button-padding" color="primary"
                (click)="generateSQL()">
                Generate SQL
              </button>
              <app-info-button [htmlText]="infoObject.predictGenerate.htmlText"
                [title]="infoObject.predictGenerate.infoTitle">
              </app-info-button>
            </mat-card-actions>
            <mat-card-content>
              <div>
                <textarea [(ngModel)]="imlService.imlFormData.predict.sqlPredict" id="sql-query"
                  value={{imlService.imlFormData.predict.sqlPredict}} placeholder={{sqlPredictPlaceholder}} rows="4"
                  cols="50">
                  </textarea>
              </div>
            </mat-card-content>

            <mat-card-actions>
              <button id="btn-execute-predict" mat-flat-button class="fr-primary button-padding" color="primary"
                (click)="editInSQLEditor('predict',imlService.imlFormData.predict.sqlPredict)">
                Execute on SQL Query Tools
              </button>
              <app-info-button [htmlText]="infoObject.predictEdit.htmlText" [title]="infoObject.predictEdit.infoTitle">
              </app-info-button>
            </mat-card-actions>

          </mat-card>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-card>
  </mat-drawer-content>


  <mat-drawer #trainDrawer class="right-drawer" mode="over" position="end" (keydown.escape)="trainDrawer.close()">

    <app-iml-training-info (hideDrawer)="trainDrawer.close()" [drawerOpened]="trainDrawer.opened">
    </app-iml-training-info>

  </mat-drawer>


</mat-drawer-container>